/* Someone should do this the right way, whatever that might be. */

@media only screen and (max-width: 600px) {
    #DataCardsPage .widgets .overview-box-count {
        position: absolute;
        top: .7em;
        right: 1rem;
        width: auto;
    }
    #DataCardsPage .widgets .widget-overview {
        min-height: auto;
        height:80px !important;
        padding: 10px;
    }
    #DataCardsPage .widgets .widget-overview .p-grid {
        margin-left: auto;
        margin-top: auto;
        display:inline-block;
        padding: 10px;
    }
    #DataCardsPage .pi-users {
        height:40px;
        position:relative;
        top:10px;
    }
    #DataCardsPage .title {
        position:relative;
        bottom:10px;
    }
    #DataCardsPage .widgets .widget-overview .overview-box-title i {
        font-size: 40px;
        margin: 0;
        display:inline-block;
    }
    .pi-xBridge {
        background-image: url(/img/xBridge-mobile.svg);
        opacity: 1;
        height: 1em;
        width: 1em;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-size: 90%;
    }
    #DataCardsPage .xBridgeImg{
      display:none;  
    }
    #DataCardsPage .xBridgeTitle{
        display:initial;  
      }
    #DataCardsPage .widgets .widget-overview .overview-box-title span {
        font-size: 1.2rem;
        font-weight: 500;
    }
    #DataCardsPage .button-container .data-location-slicer, #DataCardsPage .button-container .data-building-slicer { /* temp hack until we solve for mobile*/
        display: none;
    }
  }