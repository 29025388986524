.thelogo button {
    float: left;
}
 
.thelogo div {
    font-size: 1.75rem;
    color: #fff;
    font-weight: lighter;
    padding-left: 2.8rem;
    margin-top: -2px;
}
 

 
.concourse-visual .p-timeline-horizontal {
    padding-left: 1rem;
}
 

.p-timeline .custom-marker:last-child {
    width: 1rem;
    height: 2rem;
}
 

.p-timeline-horizontal .p-timeline-event:last-child {
    flex: 1 1;
}
 
.p-timeline-horizontal .p-timeline-event-opposite {
    font-size: .8rem;
    font-weight: bold;
    float: left;
    white-space: nowrap;

}
 
#concourseC .p-timeline-horizontal .p-timeline-event-opposite  {
    margin-top: 1.5rem;
}

#concourseA .custom-marker, #concourseB .custom-marker {
    width: 4em;
}

#concourseC .custom-marker, #concourseD .custom-marker {
    width: 3em;
}

#concourseA .custom-marker.gates, #concourseB .custom-marker.gates {
    width: 10px;
}

#concourseC .custom-marker.gates, #concourseD .custom-marker.gates {
    width: 10px;
}

.p-timeline-horizontal .gates {
    display: flex;
    width: 10px;
    background-image: url(/img/timeline-dot.png);
    background-position: 0px 10px;
    background-repeat: no-repeat;
    opacity: 0.75;
}

.p-timeline-horizontal .p-timeline-event-opposite {
    transform: rotate(45deg);
    margin-top: 1rem;
}

.p-timeline-horizontal .p-timeline-event-content {
    line-height: 0;
}

.High {
    background: #ffcdd2;
    color: #c63737;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.Medium {
    background: #feedaf;
    color: #8a5340;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.Low {
    background: #c8e6c9;
    color: #256029;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

#concourseA .p-panel-title, #concourseB .p-panel-title, #concourseC .p-panel-title, #concourseD .p-panel-title {
    font-size: 1.2rem;
}

.acaa-mensroom {
    background-image: url(/img/icon-mrr.png);
    width: 16px;
    height: 20px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    margin: -1px 0 0 2px;
}
 
.acaa-womensroom {
    background-image: url(/img/icon-wrr.png);
    width: 16px;
    height: 20px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    margin: -1px 0 0 1px;
}

.acaa-inboundflight {
    opacity: 0.5;
    background-image: url(/img/icon-inbound.png);
    background-size: 25px;
    margin-top: -13px;
    display: block;
    width: 25px;
    height: 24px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
 
}

.acaa-outboundflight {
    opacity: 0.5;
    background-image: url(/img/icon-outbound.png);
    background-size: 25px;
    margin-top: -13px;
    display: block;
    width: 25px;
    height: 24px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
}

.pi-airside {
    opacity: 0.6;
    background-image: url('/img/icon-airside.png');
    height: 16px;
    background-repeat: no-repeat;
}

.pi-airfield {
    opacity: 1;
    background-image: url('/img/icon-airfield.png');
    height: 10px;
    background-repeat: no-repeat;
}

.pi-landside {
    opacity: 0.6;
    background-image: url('/img/icon-landside.png');
    height: 19px;
    background-repeat: no-repeat;
}

.pi-baggage {
    opacity: 0.6;
    background-image: url('/img/icon-baggage.png');
    height: 19px;
    background-repeat: no-repeat;
    background-position: 2px 2px;
}

.pi-parking {
    opacity: 0.6;
    background-image: url('/img/icon-parking.png');
    height: 19px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
}

.pi-restroom {
    opacity: 0.6;
    background-image: url('/img/icon-restroom.png');
    height: 19px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
}

.pi-gate {
    opacity: 0.6;
    background-image: url('/img/icon-gate.png');
    height: 19px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
}

.pi-transit {
    opacity: 0.6;
    background-image: url('/img/icon-transit.png');
    height: 19px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
}

.pi-terminal-flow {
    transform: rotate(45deg);
}

.pi-flight-departure {
    background-image: url(/img/icon-flight-departure-white.svg);
    opacity: 1;
    height: 1em;
    width: 1em;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100%;
}

.pi-flight-arrival {
    background-image: url(/img/icon-flight-arrival-white.svg);
    opacity: 1;
    height: 1em;
    width: 1em;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100%;
}

.pi-restroom-mens {
    background-image: url(/img/icon-restroom-mens-white.svg);
    opacity: 1;
    height: 1em;
    width: 1em;
    background-repeat: no-repeat;
    background-position: 1px 0px;
    background-size: 83%;
}

.pi-restroom-womens {
    background-image: url(/img/icon-restroom-womens-white.svg);
    opacity: 1;
    height: 1em;
    width: 1em;
    background-repeat: no-repeat;
    background-position: 0px -3px;
    background-size: 90%;
}
.pi-restroom-assisted {
    background-image: url(/img/icon-restroom-assisted-white.svg);
    opacity: 1;
    height: 1em;
    width: 1em;
    background-repeat: no-repeat;
    background-position: 0px -3px;
    background-size: 90%;
}
.pi-parking-white {
    background-image: url(/img/icon-parking-white.svg);
    opacity: 1;
    height: 1em;
    width: 1em;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100%;
}

.pi-gates {
    background-image: url(/img/icon-gate-white.svg);
    opacity: 1;
    height: 1em;
    width: 1em;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 90%;
}
.pi-overcast {
    background-image: url(/img/icon-weather-overcast-circle.svg);
    opacity: 1;
    height: 4em;
    width: 4em;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100%;
    margin-bottom: -14px;
}
.pi-sunny {
    background-image: url(/img/icon-weather-sunny-circle.svg);
    opacity: 1;
    height: 4em;
    width: 4em;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100%;
    margin-bottom: -14px;
}
.pi-snow {
    background-image: url(/img/icon-weather-snow-circle.svg);
    opacity: 1;
    height: 4em;
    width: 4em;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100%;
    margin-bottom: -14px;
}
.pi-rain {
    background-image: url(/img/icon-weather-rain-circle.svg);
    opacity: 1;
    height: 4em;
    width: 4em;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100%;
    margin-bottom: -14px;
}
.pi-partly-cloudy {
    background-image: url(/img/icon-weather-partly-cloudy-circle.svg);
    opacity: 1;
    height: 4em;
    width: 4em;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100%;
    margin-bottom: -14px;
}
.green {
    background-color: #5cb85c;
    border-color: #5cb85c;
}

.yellow {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}

.red {
    background-color: #d9534f;
    border-color: #d9534f;
}

#ThingTablePage .sensor-name .status-indicator {
    color:rgb(194, 194, 194);
    padding-right: 1rem;
}

#ThingTablePage .sensor-name.status-low .status-indicator { color: #5cb85c }
#ThingTablePage .sensor-name.status-medium .status-indicator { color: #f0ad4e }
#ThingTablePage .sensor-name.status-high .status-indicator { color: #d9534f }

#ThingTablePage .sensor-name span { font-weight: 500;}

#ThingTablePage .row-high .sensor-status .sensor-status {
    background: #ffcdd2;
    color: #c63737;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

#ThingTablePage .row-medium .sensor-status .sensor-status {
    background: #feedaf;
    color: #8a5340;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

#ThingTablePage .row-low .sensor-status .sensor-status {
    background: #c8e6c9;
    color: #256029;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

#DataCardsPage .p-lg-1 {
    float: left;
}
.luggage {
    margin-top: 5px;
}
#DataCardsPage .gate {
    margin: auto;
    width: 20%;
    font-size:0.8em;
    font-weight: bold;
    padding-top: .5em;
}

#DataCardsPage .filter-item.pit {
    width: 10.7em;
    height: 10em;
    padding: 10px;
}
#DataCardsPage .filter-item.agc {
    height: 175px;
    width: 175px;
    padding: 10px;
}

#DataCardsPage .widgets .widget-overview {
    height: 125px;
    padding: 8px;
}
#DataCardsPage .widgets .widget-overview.agc {
    width: 100%;
    height: 100%;
}

#DataCardsPage .overview-box-count span {
    font-weight: 100;
}

#DataCardsPage .button-container {
    padding-bottom: 1em;
}

#DataCardsPage .button-container .p-buttonset {
    display: inline-block;
    margin: 0 1em 0 0;
}

#DataCardsPage .button-container button {
    margin-right: 0.5em;
}

#DataCardsPage .button-container button:last-child {
    margin-right: 0em;
}

#DataCardsPage .button-container .slicer {
    margin-bottom: 4px;
    display: inline-block;
}

#DataCardsPage .button-container .location-slicer {
    margin-left: 1em;
}
#DataCardsPage .xBridgeTitle{
    display:none;
}

.metrics-page .overview-box-count span {
    font-weight: 100;
}

.datatable-filter-demo .p-paginator .p-paginator-current {
    margin-left: auto;
}

.datatable-filter-demo .p-progressbar {
    height: 1.5rem;
    background-color: #d8dadc;
}

.datatable-filter-demo .p-progressbar .p-progressbar-value {
    background-color: #607d8b;
}

.datatable-filter-demo .p-datepicker {
    min-width: 25rem;
}

    .datatable-filter-demo .p-datepicker td {
        font-weight: 400;
    }

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}
#WeatherCard  > .p-card-body{
    padding: 0.5rem;
}
#TimeCard  > .p-card-body{
    padding: 0.5rem;
}
#DemoCard  > .p-card-body{
    padding: 0;
}
#dashContent.p-card.p-component div.p-card-body{
    padding: 0px !important;
}
#dashContent.p-card.p-component div.p-card-content{
    padding: 0px !important;
}
#dashContent.p-card.p-component div.p-card-content{
    padding: 0px !important;
}

.row-high {

}
.row-medium {

}
.row-low {

}
.battery-low {
    background: #ffcdd2;
    color: #c63737;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.battery-mid {
    background: #feedaf;
    color: #8a5340;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.battery-high {
    background: #c8e6c9;
    color: #256029;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}
.dept-orange {
    background: #FFD8B2;
    color: #A17242;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}
.dept-green {
    background: #C8E6C9;
    color: #416B30;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}
#mapCard > .p-card-body {
    height:100%;
    width:100%;
    padding:10px;
}
#mapCard > .p-card-body > .p-card-content {
    height:100%;
    width:100%;
    padding:0;
}
#notams > .p-card-body {
    height:100%;
    width:100%;
    padding:10px;
}
#notams > .p-card-body > .p-card-content {
    height:100%;
    width:100%;
    padding:0;
}
.mapboxgl-ctrl-bottom-left{
    display:none;
}
.mapboxgl-ctrl-bottom-right{
    display: none;
}
.mapSidebarButton {
    color: #007bff;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    text-align: center;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 4px;
  }
  #ReadingsCard  > .p-card-body{
    padding: 1rem;
}
#WeatherCard  > .p-card-body > .p-card-content{
    padding-top: 1.7rem;
}
#TimeCard  > .p-card-body > .p-card-content{
    padding: 0px;
}
.windowContainer {
    flex-direction: column;
    flex:3;
  }
.notamsBox {
    flex:2;
}
.weatherBox {
    flex:1;
}
#notams {
    height:98.7%;
}
#ReadingsCard {
    float:left;
    margin-right:5px;
}
.mapboxgl-map {
    font: inherit;
}
@media screen and (max-height: 1055px) {
    .windowContainer {
      flex-direction: row-reverse;
      flex:2;
      max-height: 64%;
    }
    .notamsBox {
        flex:1;
    }
    .weatherBox {
        flex:3;
    }
    #ReadingsCard {
        margin-right:5px;
    }
  }
  @media screen and (max-height: 1050px) and (max-width: 1730px) {
    #ReadingsCard {
        float:right;
        margin-bottom: 5px;
        margin-right:0px;
    }
}
.acaa-timeline {
  
    .line-box{
        width: 100%;
        height: 85%;
        padding-top: 20px;
        .line{
            width: 100%;
            height: 2px;
            background-color: lightblue;
        }
        .timestamps{
            position:relative;
            .timestamp{
                .timestamp-text{
                    transform: rotate(-45deg);
                    color:gray;
                    margin-left: 0px;
                    margin-top: -60px;
                    width:100px;
                    line-height: 0.9;
                    
                }
                .timestamp-text-bottom{
                    transform: rotate(45deg);
                    color:gray;
                    margin-left: 0px;
                    margin-top: 40px;
                    width:100px;
                    line-height: 0.9;
                }
                position: absolute;
                margin-top: 10px;
                height: 20px;
                width: 20px;
                border-radius: 10px;
                border: 1px solid rgb(255 255 255 / 80%);
            }   
            width: 96%;
            height:20px;
            left: 2%;
        }  
     
    }
    .times{
        margin-top: -10px;
        display: flex;
        justify-content: space-between;
        padding-left: 2%;
        padding-right: 2%;
    }
    .time{
        color: gray;
    }
    width: 100%;
    height:100%;
}
#MapStyleSelector{
    .map-style-select{
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        border: 2px solid rgb(255 255 255 / 90%);
        margin: 2px;
        cursor:pointer;
    }
    position: absolute;
    justify-content: space-between;
    top: 70px;
    right:10px;
    width:80px;
    height: 25px;
    z-index: 999;
}
.pi-exclamation-triangle{
    display: block;
    position: absolute;
    margin-top: -5px;
    margin-left:25px;
    color: darkred;

}
.elIcon{
    display: block;
    position:absolute;
    margin-left: 35px;
    margin-top: 10px;
    color: #708090;
}
.rotate180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}