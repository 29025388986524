@import 'prismjs/themes/prism-coy.css';
@import './local.css';
@import './mobile-hacks.css';

.small-select-button {
    //@extend .p-selectbutton;
    margin-left: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 5px;
    span {
        font-size: .8rem;
    }
}

.acaa-form {
    label {
        display: inline-block;
        width: 100%;
    }
    label.checkbox {
        width: auto;
    }
    > div {
        margin-top: 24px;
        display: inline-block;
        width: 50%;
        vertical-align: top;
        > span > div {
            min-width: 75%;
        }
    }
    > div.full {
        margin-top: 24px;
        display: inline-block;
        width: 100%;
    }
    > div.full.with-button {
        > span {
            display: inline-block;
            width: 75%;
            > div {
                width: 100%;
            }
        }
        > button {
            display: inline-block;
            margin-left: 8px;
        }
    }
    small {
        width: 100%;
        display: inline-block;
    }
}

.acaa.tooltip {
    background-color: #FFFFFF;
    color: #000;
    padding: 0;
    border-color: #FFF;
}

div.submit-footer {
    margin-top: 30px;
    border-top: 1px solid #999999;
    width: 100%;
    button {
        width: 50%;
        margin-left: 25%;
        margin-top: 12px;
    }
}

.cancel-footer {
    margin-top: 30px;
    border-top: 1px solid #999999;
    width: 100%;
    button {
        width: 48%;
        margin: 12px 1% 0 1%;
    }
    :nth-child(2) {
        background-color: #997570;
    }
}

.layout-wrapper .erase-padding.layout-main {
    padding: 60px 0 0 0;
}

.master-form {
    > div {
        margin-top: 24px
    }
    small {
        width: 100%;
        display: inline-block;
    }
}

.master-table {
    th:last-of-type {
        > div {
            justify-content: end;
        }
    }
    tr td:last-of-type {
        display: flex;
        justify-content: end;
    }
}

ul.p-cascadeselect-panel {
    ul.p-cascadeselect-sublist {
        min-width: 10vw;
        width: fit-content;
    }
    ul.p-cascadeselect-panel {
        ul.p-cascadeselect-panel {
            ul.p-cascadeselect-panel {
                ul.p-cascadeselect-panel {
                    max-height: 40vh;
                    overflow-y: auto;
                }
            }
        }
    }
}

.p-card.comfy {
    padding: 0;
    .p-card-body {
        padding: 0;
        .p-card-content {
            padding: 0;
        }
    }
}

.collapsible-pane {
    .collapse-content{
        overflow: hidden;
        display: inline-block;
    }

    button.minimizer {
        display: inline-block;
        margin: 4px;
        background-color: #5cb85c;
        cursor: pointer;

        .p-button-label {
            display: none;
        }
    }
    button.minimizer.left {
        vertical-align: top;
        height: calc(100% - 8px);
        max-width: 16px;
    }
    button.minimizer.right {
        vertical-align: top;
        height: calc(100% - 8px);
        max-width: 16px;
    }
    button.minimizer.top {
        width: calc(100% - 8px);
        max-height: 18px;
        padding: 1px 0;
    }
}

.location-select {
    .latlng {
        p {
            margin-top: 20px;
        }
        .p-card-body .p-card-content {
            > button {
                margin: 0 1%;
                width: 48%;
            }
            div:nth-of-type(1), div:nth-of-type(2) {
                display: inline-block;
                width: 50%;
            }
        }
    }
    tbody tr:hover {
        cursor: pointer;
    }
}

.omni-select {
    margin: 0 16px 12px 0;
    padding: 6px;
    border-radius: 4px;
    display: inline-block;
    background-color: #EEE;

    > input:nth-child(1) {
        max-width: 3rem;
        padding: 0.25rem;
    }
    > input:nth-child(n+2) {
        max-width: 2rem;
        padding: 0.25rem;
    }
    > div {
        margin-top: 6px;
    }
}

#map {
    position: relative;
    width: 100%;
    height: 100%;
}

#MapBase {
    position: absolute;
    top: 70px;
    margin-left: 8px;

    .p-card-content {
        padding: 4px 8px !important;
    }
    .switch-text {
        display: inline-block;
        margin: 2px 4px 0 0;
        vertical-align: top;
        font-size: 1.2rem;
        font-weight: bold;
    }
    .p-inputswitch {
        vertical-align: top;
        margin: 2px 0;
    }
    .jump-btns {
        display: inline-block;
        margin-left: 6px;

        button {
            margin-left: 6px;
            padding: 4px 8px;
            background-color: #ced4da;
            color: #333;
            font-weight: bold;
        }
    }
}

#AgcStatusMap {
    > #map {
        height: 100%;
    }
    > #MapBase {
        display: none;
    }
    .map-filters {
        position: absolute;
        top: 70px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        width: 400px;

        button {
            margin-right: 4px;
        }
        #TimeCard {
            display: inline-block;
            height: 35px;
            padding: 0 10px;
            background: #EEF2F6;
            border-radius: 15px;
            border: 1px solid rgba(207, 198, 196, 1);
            font-size: 20px;
            padding-top: 1px;
        }
    }
    .overlay {
        position: absolute;
        padding: 4px;

        > * {
            display: inline-block;
        }
        &.hidden {
            width: 0 !important;
        }
        &.inner-map {
            border: #FFF 5px solid;
            bottom: 135px;
            right: 30px;
            width: 800px;
            height: 300px;
            background: #FFF;
            box-shadow: 0px 4px 23px 0px;

            #MapBase {
                display: none;
            }
            #MapStyleSelector{
                display: none;
            }
            .notam-marker {
                width: 28px;
                height: 28px;
                padding-top: 4px;
                border-radius: 4px;
                background: #708090;
                color: #EEE;
                text-align: center;
                font-weight: bold;
            }
            .sensor-marker {
                width: 32px;
                height: 32px;
                padding-top: 6px;
                border-radius: 20px;
                background: #5A5;
                color: #EEE;
                text-align: center;
                font-weight: bold;
            }
        }
        &.timeline {
            bottom: 0px;
            right: 0px;
            width: 100%;
            height: 100px;
            background: #FFF;
            box-shadow: 0px 4px 23px 0px;

            &.menuState {
                width: calc(100% - 234px);
            }
            .timelines {
                height: 100%;
                width: 100%;
                position: relative;
                
                > div {
                    display: inline-block;
                    width: 49%;
                }
                > div:nth-of-type(2) {
                    position: absolute;
                    right: 0;
                }
                .p-panel-header {
                    padding: 0.75rem;
                }
                .p-panel-content {
                    padding: 0 1rem .25rem 1rem;
                }
                .p-timeline-event {
                    .p-timeline-event-opposite {
                        margin-top: 0;
                    }
                    .p-timeline-event-separator button {
                        height: 8px;
                        width: 8px;
                        padding: 10px 14px 10px 6px;
                        font-weight: bold;

                        &.no-data {
                            background-color: #555;
                        }
                    }
                    .p-timeline-event-content {
                        padding: 0 0 1rem 0;
                        margin-top: 8px;
                    }
                }
            }
        }
    }
    .layer-button {
        position: absolute;
        z-index: 999;
        bottom: 140;
        background: #FFF;
    }
    iframe {
        width: 100%;
        height: calc(100% - 4px);
    }
    &.hidden {
        > #map {
            width: 0;
            height: 0;
            min-height: 0;
            padding: 0;
        }
        .overlay {
            width: 0;
            height: 0;
            padding: 0;

            &.timeline {
                display: none;
            }
        }
    }
}

.notamsCard {
    position: absolute;
    max-height: 55vh;
    width: 400px;
    border: #FFF 5px solid;
    bottom: 135px;
    left: 35px;
    background: #FFF;
    box-shadow: 0px 4px 23px 0px;

    &.menuState {
        left: 270px;
    }
}

#DataCardsPage .everything {
    .flight.departing,.flight.arrived,.flight.incoming,.tsa,.parking,.restroom.low,.flow.apm-to-airside.low {
        background-color: #5cb85c;
        border-color: #5cb85c;
    }
    .tsa.medium,.parking.medium,.restroom.medium,.flow.apm-to-airside.medium {
        background-color: #f0ad4e;
        border-color: #f0ad4e;
    }
    .flight.delayed,.tsa.high,.parking.high,.restroom.high,.flow.apm-to-airside.high {
        background-color: #d9534f;
        border-color: #d9534f;
    }
    .temps.normal {
        background-color: #537dbf;
        border-color: #537dbf;
    }
    .temps.low {
        background-color: #69b365;
        border-color: #69b365;
    }
    .temps.lower{
        background-color: #f1b618;
        border-color: #f1b618;
    }
    .temps.lowest{
        background-color: #e46d6d;
        border-color: #e46d6d;
    }
    .flight.departed {
        background-color: #999;
        border-color: #999;
    }

    .flow.apm-to-airside .pi-users {
        color: #fff;
        opacity: 0.8;
        line-height: 0.5;
    }

    .filter-item > div div {
        display: inline-block;
    }

    .priority,.things,.location {
        display: none;
    }

    .overview-box-title {
        white-space: nowrap;
    }
    .overview-box-stats span.old {
        display: inline-block;
        margin-top: 2px;
        padding: 1px 2px;
        border: dashed 2px #000000;
        border-radius: 2px;
    }

    .clickable:hover {
        padding: 5px;
        border: 3px solid #000000;
        border-radius: 4px;
        cursor: pointer;
    }
    .pit.clickable:hover {
        .overview-box-count {
            display: none;
        }
        .overview-box-stats {
            margin-top: 4px;
        }
    }
}

@media only screen and (max-width: 600px) {
    #DataCardsPage .everything {
        .filter-item.pit,.filter-item.agc {
            width: 93%;
            height:80px !important;
            padding: 0 0 5px 0;
            margin-bottom:5px;
        }
        .overview-box-stats {
            margin-left: 50px;
            margin-top: 1rem;
            position: relative;
            bottom: 20px;
            max-width: 70%;
        }
        .clickable:hover {
            .overview-box-count {
                display: inline-block;
            }
            .overview-box-stats {
                margin-top: 1rem;
            }
        }
    }

    .temp-hist-chart {
        padding: 0 !important;
        
        > div > div > div {
            width: 100%;
            height: 300px;
            overflow-x: scroll;
        }
    }
}